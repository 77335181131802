<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img src="@/assets/teamModalLogo.svg" height="85" width="100" />
          </div>
          <div class="ml-1 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleAddEditTeamModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form lazy-validation ref="teamForm" class="pt-10">
            <v-row no-gutters justify="center" v-if="layout==='AdminDashboardLayout'">
              <v-col cols="12">
                <v-text-field
                  label="First Name"
                  outlined
                  dense
                  v-model="first_name"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
             <v-row no-gutters justify="center" v-if="layout==='AdminDashboardLayout'">
              <v-col cols="12">
                <v-text-field
                  label=" Last Name"
                  outlined
                  dense
                  v-model="last_name"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
             <v-row no-gutters justify="center" v-if="layout!='AdminDashboardLayout'">
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  outlined
                  dense
                  v-model="name"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="12">
                <v-text-field
                  label="Phone Number"
                  outlined
                  dense
                     v-model="phone"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
             <v-row no-gutters justify="center" >
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  outlined
                  dense
                     v-model="email"
                  :rules="[rules.required, rules.email]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
              <v-row no-gutters justify="center"  v-if="layout!='AdminDashboardLayout'">
              <v-col cols="12">
                <v-text-field
                  label="Designation"
                  outlined
                  dense
                  v-model="designation"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>   
                
            <v-row no-gutters justify="center"  v-if="layout==='AdminDashboardLayout'">
              <v-col cols="12">
                <v-autocomplete
                  label="Role"
                  outlined
                  dense
                  :rules="[rules.required]"
                  v-model="role"
                  :items="roleList"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-7 px-10" v-if="layout==='AdminDashboardLayout'">
          <v-spacer></v-spacer>
          <v-btn
            v-if="type == 'add'"
            :loading="formLoading"
            class="rounded-lg dialogAction-btnText py-5 px-4"
            dark
            color="#38227A"
            @click="submitForm"
        >
            <span>Add User</span>
          </v-btn>
        
          <v-btn
            :loading="formLoading"
            v-if="type == 'edit'"
            class="rounded-lg dialogAction-btnText py-5 px-4"
            dark
            color="#38227A"
            @click="submitForm"
          >
            <span>Save</span></v-btn
          >
        </v-card-actions>
         <v-card-actions class="pt-0 pb-7 px-10" v-if="layout!='AdminDashboardLayout'">
          <v-spacer></v-spacer>
          <v-btn
            v-if="type == 'add'"
            :loading="formLoading"
            class="rounded-lg dialogAction-btnText py-5 px-4"
            dark
            color="#38227A"
            @click="submitCampaignForm"
        >
            <span>Add User</span>
          </v-btn>
        
          <v-btn
            :loading="formLoading"
            v-if="type == 'edit'"
            class="rounded-lg dialogAction-btnText py-5 px-4"
            dark
            color="#38227A"
            @click="submitForm"
          >
            <span>Save</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RULES from "@/common/fieldRules";
import {
  API_ADMIN_TEAM_MANAGEMENT_GET_ROLE,
  API_ADMIN_TEAM_MANAGEMENT_POST,
  API_ADMIN_TEAM_MANAGEMENT_POSTS,
  API_ADMIN_TEAM_MANAGEMENT_UPDATE,
  API_ADMIN_TEAM_MANAGEMENT_GET,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "AddEditTeamModal",
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      name: "",
      first_name:"",
      last_name:"",
      email: "",
      phone:"",
      role: null,
      roleList: [],
      teamList:[],
      designation:""
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "teamManagement/getShow",
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    layout() {
      return this.$route.meta.layout;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddEditTeamModal({ show: value });
      },
    },
    type() {
    console.log(this.$store.state.teamManagement.addEditViewModal)
      return this.$store.state.teamManagement.addEditViewModal.type;
    },
    toastMessage(){
      if(this.type==="add"){
        return "Team Member Added"
      }else{
        return "Team Member Updated"
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add New User";
        case "edit":
          return "Edit User";
        default:
          return "";
      }
    },
  },
  watch: {
    show(value) {
      if (value) {
        // Modal open callback
        this.openCallBackModal();
      } else {
        // Modal close callback
        this.closeCallBackModal();
      }
    },
  },
  methods: {
    ...mapActions({
      // sponsor Modal
      toggleAddEditTeamModal: "teamManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    openCallBackModal() {
      console.log("open modal");
      this.getRole();
    },
    closeCallBackModal() {
      this.loading = false;
      this.formLoading = false;
      this.name = "";
      this.email = "";
      this.role = null;
      this.roleList = [];
      this.$refs.teamForm.reset();
    },
    getRole() {
      const self = this;
      self.loading = true;

      const successHandler = (result) => {
        const data = result.data;
        console.log("dasdasdas",result.data);
        self.roleList = data.result.user_role;
         console.log("user_role",self.roleList);
        self.loading = false;
      };
      const failureHandler = (result) => {
        console.log(result.data);
        self.loading = false;
      };
      const finallyHandler = function () {
        console.log("in finally");
        if (self.type === "edit") {
          self.getTeamDetail();
        }
      };

      let formData = {};
      Axios.request_GET(
        API_ADMIN_TEAM_MANAGEMENT_GET_ROLE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getTeamDetail() {
      const self = this;
      self.loading = true;
      const successHandler = (res) => {
        const data = res.data;
        console.log(data);
        this.first_name = data.team_detail.first_name;
        this.last_name = data.team_detail.last_name;
        this.email = data.team_detail.email;
         this.phone = data.team_detail.phone;
        this.role = data.team_detail.user_type;
        console.log("this.role",this.role)
        this.designation = data.team_detail.designation
        self.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res.data);
        self.loading = false;
      };
      let formData = {};
      formData["user_id"] =
        self.$store.state.teamManagement.addEditViewModal.teamID;
      Axios.request_GET(
        API_ADMIN_TEAM_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitForm() {
      const self = this;
      self.formLoading = true;
      const successHandler = (res) => {
        const data = res.data;
        self.roleList = data.role_list;
        console.log(data);
        self.formLoading = false;
        self.toggleAddEditTeamModal({ show: false });
        self.$emit("reload");
        this.showToast({
          message: this.toastMessage,
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res.data);
        self.formLoading = false;
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = {};
      formData["first_name"] = this.first_name;
      formData["last_name"] = this.last_name;
      formData["phone"] = this.phone;
      formData["email"] = this.email;
      if(this.role){
        formData["user_role"] = this.role;
      }

      if (this.type === "edit") {
        formData["user_id"] =
          this.$store.state.teamManagement.addEditViewModal.teamID;
      }
      if (this.layout === "CampaignDashboardLayout") {
        if (this.$route.matched[0].path.substring(1) === "district") {
          formData["district_id"] =
            this.selectedCampaignData.organizationData.id;
          formData["district_user_designation"]=this.designation
        } else {
          formData["school_id"] =
            this.selectedCampaignData.organizationData.id;
          formData["school_user_designation"]=this.designation
        }
      }else{
        formData["designation"]=this.designation
      }
      console.log(formData)
      if (this.type === "add") {
        Axios.request_POST(
          API_ADMIN_TEAM_MANAGEMENT_POSTS,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      } else {
        Axios.request_PATCH(
          API_ADMIN_TEAM_MANAGEMENT_UPDATE,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
      submitCampaignForm() {
      const self = this;
      self.formLoading = true;
      const successHandler = (res) => {
        const data = res.data;
        self.roleList = data.role_list;
        console.log(data);
        self.formLoading = false;
        self.toggleAddEditTeamModal({ show: false });
        self.$emit("reload");
        this.showToast({
          message: this.toastMessage,
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res.data);
        self.formLoading = false;
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = {};
      formData["user_name"] = this.name;
      formData["phone"] = this.phone;
      formData["user_email"] = this.email;
      if(this.role){
        formData["user_role"] = this.role;
      }

      if (this.type === "edit") {
        formData["user_id"] =
          this.$store.state.teamManagement.addEditViewModal.teamID;
      }
      if (this.layout === "CampaignDashboardLayout") {
        if (this.$route.matched[0].path.substring(1) === "district") {
          formData["district_id"] =
            this.selectedCampaignData.organizationData.id;
          formData["district_user_designation"]=this.designation
        } else {
          formData["school_id"] =
            this.selectedCampaignData.organizationData.id;
          formData["school_user_designation"]=this.designation
        }
      }else{
        formData["designation"]=this.designation
      }
      console.log(formData)
      if (this.type === "add") {
        Axios.request_POST(
          API_ADMIN_TEAM_MANAGEMENT_POST,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      } else {
        Axios.request_PATCH(
          API_ADMIN_TEAM_MANAGEMENT_UPDATE,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },   
   
  },
};
</script>
<style scoped>
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
</style>
